import React from 'react'
import styled from 'styled-components'
import GithubMark from '../images/GitHub-Mark-Light-32px.png'
import TwitterMark from '../images/Twitter_Social_Icon_Circle_White.png'
import LinkedInMark from '../images/LI-In-Bug.png'

const Footer = ({ className, style }) => (
  <footer className={className} style={style}>
    <h4>Levi Butcher</h4>
    <a href="https://github.com/LeviButcher ">
      <img src={GithubMark} alt="Github" />
    </a>
    <a href="https://twitter.com/LeviButcher">
      <img src={TwitterMark} alt="Twitter" />
    </a>
    <a href="https://www.linkedin.com/in/levi-butcher-24938b178/">
      <img src={LinkedInMark} alt="LinkIn" />
    </a>
    <p>Made with Gatsby</p>
  </footer>
)

export default styled(Footer)`
  margin-block: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-flow: row wrap;

  * {
    margin: 0;
  }

  img {
    filter: invert(50%);
    width: 20px;
  }

  > a {
    line-height: 1em;
  }

  * + * {
    margin-left: 1em;
  }
`
