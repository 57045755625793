import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, {
  createGlobalStyle,
  ThemeProvider,
  css,
} from 'styled-components'
import 'normalize.css'
import { useTransition, animated } from 'react-spring'
import Header from './header'
import Footer from './Footer'

const theme = {
  colors: {
    primary: 'hsla(210, 100%, 50%, 1)',
    primaryLight: 'hsla(210, 100%, 60%, 1)',
    primaryLighter: 'hsla(210, 100%, 70%, 1)',
    primaryDark: 'hsla(210, 100%, 40%, 1)',
    primaryDarker: 'hsla(210, 100%, 30%, 1)',
    secondary: 'hsla(129, 80%, 62%, 1)',
    secondaryLight: 'hsla(129, 80%, 72%, 1)',
    secondaryLighter: 'hsla(129, 80%, 82%, 1)',
    secondaryDark: 'hsla(129, 80%, 52%, 1)',
    secondaryDarker: 'hsla(129, 80%, 42%, 1)',
    tertiary: 'hsla(345, 100%, 50%, 1)',
    tertiaryLight: 'hsla(345, 100%, 60%, 1)',
    tertiaryLighter: 'hsla(345, 100%, 70%, 1)',
    tertiaryDark: 'hsla(345, 100%, 40%, 1)',
    tertiaryDarker: 'hsla(345, 100%, 30%, 1)',
    black: 'hsla(0, 0%, 13%, 1)',
    white: 'hsla(210, 100%, 95%, 1)',
    gray: 'hsla(0, 0%, 80%, 1)',
    grayLight: 'hsla(0, 0%, 93%, 1)',
    grayDark: 'hsla(0, 0%, 70%, 1)',
    grayDarker: 'hsla(0, 0%, 60%, 1)',
    grayDarkest: 'hsla(0, 0%, 20%, 1)',
  },
  gutter: '5rem',
}

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    section {
      margin: ${theme.gutter} 0;
    }

    html,
    .root {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      line-height: 29px;

      @media (max-width: 700px) {
        font-size: 0.9rem;
      }
    }
    body,
    .article {
      font-size: 1em;
      line-height: 1.61111111em;
      background: ${theme.colors.black};
      max-width: 1200px;
      margin: auto;
      padding: 0 2rem;
      color: ${theme.colors.gray};
      overflow-x: hidden;
    }
    h1,
    .h1 {
      font-size: 4.22222222em;
      line-height: 1.14473684em;
      margin-top: 0.38157895em;
      margin-bottom: 0.7631579em;
    }
    h2,
    .h2 {
      font-size: 2.61111111em;
      line-height: 1.23404255em;
      margin-top: 0.61702128em;
      margin-bottom: 0.61702128em;
    }
    h3,
    .h3 {
      font-size: 1.61111111em;
      line-height: 1em;
      margin-top: 1em;
      margin-bottom: 0em;
    }
    h4,
    .h4 {
      font-size: 1em;
      line-height: 1.61111111em;
      margin-top: 1.61111111em;
      margin-bottom: 0em;
    }
    h5,
    .h5 {
      font-size: 1em;
      line-height: 1.61111111em;
      margin-top: 1.61111111em;
      margin-bottom: 0em;
    }
    p,
    ul,
    ol,
    pre,
    table,
    blockquote {
      margin-top: 0em;
      margin-bottom: 1.61111111em;
    }
    ul ul,
    ol ol,
    ul ol,
    ol ul {
      margin-top: 0em;
      margin-bottom: 0em;
    }

    /* Let's make sure all's aligned */
    hr,
    .hr {
      border: 1px solid;
      margin: -1px 0;
    }
    a,
    b,
    i,
    strong,
    em,
    small,
    code {
      line-height: 0;
    }
    sub,
    sup {
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    sup {
      top: -0.5em;
    }
    sub {
      bottom: -0.25em;
    }

    a {
      text-decoration: none;
      font-weight: bold;

      :nth-child(3n + 1) {
        color: ${theme.colors.primaryLighter};
        :hover,
        :focus {
          color: ${theme.colors.primary};
        }
      }
      :nth-child(3n + 2) {
        color: ${theme.colors.secondaryLight};
        :hover,
        :focus {
          color: ${theme.colors.secondary};
        }
      }
      :nth-child(3n + 3) {
        color: ${theme.colors.tertiaryLighter};
        :hover,
        :focus {
          color: ${theme.colors.tertiaryLight};
        }
      }

      :active {
        outline: none;
      }
    }

    h1 {
      color: ${theme.colors.primary};
    }
    h2 {
      color: ${theme.colors.secondary};
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1em;
    }
    h2:after {
      content: '';
      display: block;
      height: 2px;
      background: ${theme.colors.primary};
      align-self: center;
    }
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.colors.grayLight};
    }
  `}
`

const SiteLayout = styled.div`
  display: grid;
  grid-template:
    'header' auto
    'content' 1fr
    'footer' auto;
  height: 100vh;
`

function Layout({ children, location }) {
  const previousPages = useRef([])

  if (!previousPages.current.some(x => x.key === children.key)) {
    previousPages.current.push(children)
  }

  const transitions = useTransition(location, i => i.pathname, {
    from: {
      gridArea: 'content',
      width: '100%',
      transform: 'translate(-100vw, 0)',
    },
    enter: { transform: 'translate(0%, 0)' },
    leave: { transform: 'translate(100vw, 0)' },
    unique: true,
    reset: true,
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SiteLayout>
        <Header style={{ gridArea: 'header' }} />
        {transitions.map(({ item, key, props }) => (
          <animated.div key={key} style={props}>
            <main>
              {previousPages.current.find(
                x => x.key === item.pathname || x.key === item.pathname + '/'
              ) || children}
            </main>
            <Footer style={{ gridArea: 'footer' }} />
          </animated.div>
        ))}
      </SiteLayout>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
