import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Nav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-block: 1em;

  > a {
    font-size: 1.4em;
    color: ${props => props.theme.colors.white}!important;
    text-decoration: none;
    margin-right: 1em;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    line-height: 1em;
    transition: border 0.5s, color 0.5s;

    &[aria-current='page']:nth-child(3n + 1),
    &:nth-child(3n + 1):hover {
      border-color: ${props => props.theme.colors.primary};
    }
    &[aria-current='page']:nth-child(3n + 2),
    &:nth-child(3n + 2):hover {
      border-color: ${props => props.theme.colors.secondary};
    }
    &[aria-current='page']:nth-child(3n + 3),
    &:nth-child(3n + 3):hover {
      border-color: ${props => props.theme.colors.tertiary};
    }

    :first-child {
      margin-right: auto;
    }
  }

  @media (max-width: 470px) {
    & {
      justify-content: center;
      text-align: center;
      a:first-child {
        margin: 1em 0.5em;
      }
      a {
        margin: 1em 0.5em;
      }
    }
  }
`

const Header = ({ style }) => (
  <header style={style}>
    <Nav>
      <Link to="/">Levi Butcher</Link>
      <Link to="/resume">Resume</Link>
      <Link to="/projects">Projects</Link>
      <Link to="/blog">Blog</Link>
    </Nav>
  </header>
)

export default Header
